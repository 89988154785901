<template>
  <a-row>
    <a-col :span="6">
      <a-input
        placeholder="Имя сотрудника"
        :value="username"
        @input="$emit('username-change', $event.target.value)"
      >
      </a-input>
    </a-col>
    <a-col :span="6">
      <a-input
        placeholder="IP адрес"
        :style="{ marginLeft: '20px' }"
        :value="remoteAdress"
        @input="$emit('remote-adress-change', $event.target.value)"
      >
      </a-input>
    </a-col>
  </a-row>
</template>
<script>
export default {
  props: {
    username: {
      type: [String, Number],
      default: null,
    },
    remoteAdress: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>
