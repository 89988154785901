<template>
  <div>
    <TableFilters
      :username="username"
      :remote-adress="remoteAdress"
      @username-change="updateFilter('username', $event)"
      @remote-adress-change="updateFilter('remoteAdress', $event)"
    />

    <a-divider />

    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :scroll="{ x: 3000 }"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: sessionsTotal }"
      :data-source="sessions"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        style="display: flex; justify-content: center"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } сеанс?`
          "
          @confirm="updateSession(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active" />
        </a-popconfirm>
      </div>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import TableFilters from './TableFilters.vue'
import { debounce } from '@/utils'
import {
  SESSIONS,
  GET_SESSIONS,
  SESSIONS_TOTAL,
  UPDATE_SESSION,
  AUTH_USER_IS_ADMIN,
} from '@/store/types'

export default {
  components: { TableFilters },
  data() {
    return {
      columns,
      loading: false,
      username: '',
      remoteAdress: '',
      pageNumber: 1,
      tagColorByActivity: {
        1: 'green',
        0: 'red',
      },
      sessionIsActive: {
        1: 'Активный',
        0: 'Неактивный',
      },
    }
  },
  computed: {
    sessions() {
      return this.$store.getters[SESSIONS]
    },
    sessionsTotal() {
      return this.$store.getters[SESSIONS_TOTAL]
    },
    userIsAdmin() {
      return this.$store.getters[AUTH_USER_IS_ADMIN]
    },
  },
  watch: {
    username() {
      this.pageNumber = 1
      this.getSessions()
    },
    remoteAdress() {
      this.pageNumber = 1
      this.getSessions()
    },
  },
  created() {
    this.username = this.$route.query.username || this.username
    this.remoteAdress = this.$route.query.remoteAdress || this.remoteAdress

    this.getSessions()
  },
  methods: {
    updateFilter(filter, value) {
      this[filter] = typeof value === 'string' ? value.trim() : value
      this.pageNumber = 1
      this.$router
        .replace({
          name: 'sessions',
          query: {
            ...this.$route.query,
            [filter]: typeof value === 'string' ? value.trim() : value,
            pageNumber: 1,
          },
        })
        .catch(err => err)

      this.getSessions()
    },
    getSessions: debounce(function() {
      const { username, remoteAdress, pageNumber } = this

      this.$store.dispatch(GET_SESSIONS, {
        username,
        remoteAdress,
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'sessions',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getSessions()
    },
    updateSession(session, field) {
      this.$store.dispatch(UPDATE_SESSION, {
        ...session,
        [field]: !session[field],
      })
    },
  },
}
</script>
