export default [
  {
    title: 'ID',
    dataIndex: 'id',
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Активная',
    fixed: 'left',
    width: 115,
    scopedSlots: { customRender: 'is_active' },
  },
  {
    title: 'Логин',
    dataIndex: 'user.username',
  },
  {
    title: 'Имя',
    dataIndex: 'user.first_name',
  },
  {
    title: 'Фамилия',
    dataIndex: 'user.last_name',
  },
  {
    title: 'IP адресс',
    dataIndex: 'remote_address',
  },
  {
    title: 'Данные',
    dataIndex: 'user_agent',
    width: 800,
  },
  {
    title: 'Создано',
    dataIndex: 'created_at',
    fixed: 'right',
    width: 180,
    customRender: date => new Date(date).toLocaleString('ru-RU'),
  },
  {
    title: 'Изменено',
    dataIndex: 'updated_at',
    fixed: 'right',
    width: 180,
    customRender: date =>
      date ? new Date(date).toLocaleString('ru-RU') : 'Без изменений',
  },
]
